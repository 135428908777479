import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
	@Output() clickOutside = new EventEmitter<void>();

	constructor(private elementRef: ElementRef) {}

	@HostListener('document:click', ['$event.target'])
	public onDocumentClick(targetElement: any) {
		console.log('onDocumentClick');

		const clickedInside = this.elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			setTimeout(() => {
				this.clickOutside.emit();
			}, 0);
		}
	}
}
