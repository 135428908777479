import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
	@Output() onPageChange = new EventEmitter<number>();

	@Input() page!: number;
	@Input() totalPages!: number;
	@Input() disabled: boolean = false;
	@Input() hide: boolean = false;

	nextPage() {
		if (this.page + 1 <= this.totalPages) {
			this.page++;
			this.onPageChange.emit(this.page);
		}
	}

	prevPage() {
		if (this.page - 1 >= 1) {
			this.page--;
			this.onPageChange.emit(this.page);
		}
	}

	setPage(page: number) {
		this.page = page;
		this.onPageChange.emit(this.page);
	}

	canShowPage(page: number): boolean {
		// Mostrar siempre ultima y primer pagina
		if (page == 1 || page == this.totalPages) {
			return true;
		}

		// Offset Inicial
		if (this.page <= 3) {
			if (page <= 4) {
				return true;
			}
		}

		// Offset final
		if (this.totalPages - this.page >= 0 && this.totalPages - this.page <= 1) {
			if (page >= this.totalPages - 3) {
				return true;
			}
		}

		// Mostrar pagina actual y vecinas
		if (page >= this.page - 1 && page <= this.page + 1) {
			return true;
		}

		// No mostrar el resto de paginas
		return false;
	}
}
