import { Component, OnInit } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
	selector: 'app-tabs-stepper',
	templateUrl: './tabs-stepper.component.html',
	styleUrls: ['./tabs-stepper.component.scss'],
	providers: [{ provide: CdkStepper, useExisting: TabsStepperComponent }]
})
export class TabsStepperComponent extends CdkStepper implements OnInit {
	ngOnInit(): void {
		//this.selectedIndex = 0;
	}

	selectStepByIndex(index: number): void {
		this.selectedIndex = index;
	}

	getActiveStep(): number {
		return this.selectedIndex;
	}
}
