<div
	class="w-full bg-white rounded-[0.25rem] border border-slate-200 shadow-sm"
	cdkAccordion
	[ngClass]="[absoluteContent ? 'absolute' : '', accordionItem.expanded ? '!shadow-lg' : '']"
>
	<cdk-accordion-item #accordionItem="cdkAccordionItem">
		<header
			class="border-slate-100 cursor-pointer"
			[ngClass]="accordionItem.expanded ? 'border-b' : ''"
			(click)="accordionItem.toggle()"
		>
			<div class="flex justify-between items-center w-full">
				<div>
					<h2 class="font-semibold text-slate-800 form-input border-none !shadow-none">Inventario de Zonas</h2>
				</div>
				<div>
					<button class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600 h-fit px-1 border-none !shadow-none">
						<div
							class="w-6 h-6 grid place-content-center"
							[ngClass]="accordionItem.expanded ? 'rotate-180' : 'rotate-0'"
						>
							<i
								class="fa-duotone fa-chevron-down"
								style="font-size: 1rem"
							></i>
						</div>
					</button>
				</div>
			</div>
		</header>
		<div
			class="flex-col h-full bg-white"
			[ngClass]="accordionItem.expanded ? 'flex' : 'hidden'"
		>
			<div class="grow px-5 pt-3 pb-1">
				<div class="overflow-x-auto">
					<table class="table-auto w-full">
						<thead class="text-xs uppercase text-slate-400">
							<tr>
								<th class="py-2 w-1/2">
									<div class="font-semibold text-left">Zonas</div>
								</th>
								<th class="py-2 w-1/2">
									<div class="font-semibold text-right">Disponible</div>
								</th>
							</tr>
						</thead>
						<tbody class="text-sm divide-y divide-slate-100">
							<tr>
								<td class="py-2">
									<div class="text-left">Bronce</div>
								</td>
								<td class="py-2">
									<div class="font-medium text-right text-slate-800">94</div>
								</td>
							</tr>
							<tr>
								<td class="py-2">
									<div class="text-left">Plata</div>
								</td>
								<td class="py-2">
									<div class="font-medium text-right text-slate-800">42</div>
								</td>
							</tr>
							<tr>
								<td class="py-2">
									<div class="text-left">Oro</div>
								</td>
								<td class="py-2">
									<div class="font-medium text-right text-slate-800">12</div>
								</td>
							</tr>
							<tr>
								<td class="py-2">
									<div class="text-left">Diamante</div>
								</td>
								<td class="py-2">
									<div class="font-medium text-right text-slate-800">
										<div class="text-xs inline-flex font-medium bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">AGOTADO</div>
									</div>
								</td>
							</tr>
							<tr>
								<td class="py-2">
									<div class="text-left">Platino</div>
								</td>
								<td class="py-2">
									<div class="font-medium text-right text-slate-800">
										<div class="text-xs inline-flex font-medium bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">AGOTADO</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</cdk-accordion-item>
</div>
