import { Component, OnInit, OnDestroy, inject, AfterViewInit, afterNextRender, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject } from 'rxjs';
import { AlertService } from '../../../shared/services/alert.service';

@UntilDestroy()
@Component({
	selector: 'app-global-base',
	template: ''
})
export class GlobalBaseComponent implements OnInit, OnDestroy, AfterViewInit {
	protected loadingStates: any = {};
	protected ngUnsubscribe: Subject<void> = new Subject<void>();
	protected clientRenderReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	_router = inject(Router);
	formBuilder = inject(FormBuilder);
	alertService = inject(AlertService);
	cdr = inject(ChangeDetectorRef);

	constructor() {
		afterNextRender(() => {
			this.clientRenderReady.next(true);
		});
	}

	ngOnInit(): void {
		this.ngOnInitOverride();
	}

	ngAfterViewInit(): void {
		this.ngAfterViewInitOverride();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.ngOnDestroyOverride();
	}

	/**
	 * Children override for ngOnInit callback
	 */
	ngOnInitOverride(): void {}

	/**
	 * Children override for ngAfterViewInit callback
	 */
	ngAfterViewInitOverride(): void {}

	/**
	 * Children override for ngOnDestroy callback
	 */
	ngOnDestroyOverride(): void {}
}
