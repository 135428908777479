import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-indeterminate-loader',
	templateUrl: './indeterminate-loader.component.html',
	styleUrls: ['./indeterminate-loader.component.scss']
})
export class IndeterminateLoaderComponent {
	@Input() zIndex: string = '2';
	@Input() position: string = 'fixed';
	@Input() left: string = 'inherit';
	@Input() top: string = 'inherit';
}
