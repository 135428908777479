<div
	[formGroup]="formGroup"
	class="contents"
>
	<label
		*ngIf="label"
		class="block text-sm font-medium mb-1"
		[htmlFor]="inputSignature"
		>{{ label }}</label
	>

	<div class="relative">
		<ng-select
			*ngIf="inputType == 'ngselect'"
			class="override"
			[ngClass]="{ labelTemplate: selectLabelTemplate, optionTemplate: selectOptionTemplate, iconLeft: icon && iconPos == 'left', iconRight: icon && iconPos == 'right' }"
			[id]="inputSignature"
			[items]="items"
			[searchable]="searchable"
			[clearable]="clearable"
			[formControlName]="controlName"
			[name]="controlName"
			[multiple]="multiple"
			[readOnly]="readOnly"
			[bindLabel]="bindLabel"
			[placeholder]="placeholder"
			[bindValue]="bindValue"
			[searchFn]="searchFn ? searchFn : null"
			(search)="onSearch($event)"
			(blur)="onBlur($event)"
			(focus)="onFocus($event)"
			(open)="onOpen($event)"
			(change)="onNgSelectChange($event)"
			(ngModelChange)="onChange($event)"
		>
			<ng-template
				*ngIf="selectLabelTemplate"
				ng-label-tmp
				let-item="item"
				let-index="index"
				let-search="searchTerm"
			>
				<ng-container *ngTemplateOutlet="selectLabelTemplate; context: { item: item, index: index, searchTerm: search }"></ng-container>
			</ng-template>

			<ng-template
				*ngIf="selectOptionTemplate"
				ng-option-tmp
				let-item="item"
				let-index="index"
				let-search="searchTerm"
			>
				<ng-container *ngTemplateOutlet="selectOptionTemplate; context: { item: item, index: index, searchTerm: search }"></ng-container>
			</ng-template>
		</ng-select>

		<textarea
			*ngIf="inputType == 'textarea'"
			[id]="inputSignature"
			[formControlName]="controlName"
			[name]="controlName"
			class="form-textarea w-full block"
			[class]="class"
			[ngClass]="{ '!border-rose-300': showErrorState && formGroup.get(controlName)?.invalid && (formGroup.get(controlName)?.dirty || formGroup.get(controlName)?.touched), '!pr-[2.375rem]': iconPos == 'right' && icon, '!pl-[2.375rem]': iconPos == 'left' && icon }"
			[autocomplete]="autoComplete"
			[readOnly]="readOnly"
			[required]="required"
			[spellcheck]="spellCheck"
			[placeholder]="placeholder"
			(change)="onChange($event)"
			(ngModelChange)="onChange($event)"
			(input)="onInput($event)"
		></textarea>

		<input
			*ngIf="['text', 'number', 'email', 'password'].includes(inputType)"
			class="form-input w-full"
			[class]="class"
			[ngClass]="{ '!border-rose-300': showErrorState && formGroup.get(controlName)?.invalid && (formGroup.get(controlName)?.dirty || formGroup.get(controlName)?.touched), '!pr-[2.375rem]': iconPos == 'right' && icon, '!pl-[2.375rem]': iconPos == 'left' && icon }"
			[id]="inputSignature"
			[formControlName]="controlName"
			[name]="controlName"
			[type]="inputType"
			[min]="min"
			[max]="max"
			[ngModel]="value"
			[autocomplete]="autoComplete"
			[readOnly]="readOnly"
			[required]="required"
			[spellcheck]="spellCheck"
			[inputMask]="inputMask"
			[placeholder]="placeholder"
			(change)="onChange($event)"
			(ngModelChange)="onChange($event)"
			(input)="onInput($event)"
		/>

		<input
			*ngIf="inputType == 'date'"
			class="form-input w-full"
			[class]="class"
			[ngClass]="{ '!border-rose-300': showErrorState && formGroup.get(controlName)?.invalid && (formGroup.get(controlName)?.dirty || formGroup.get(controlName)?.touched), '!pr-[2.375rem]': iconPos == 'right' && icon, '!pl-[2.375rem]': iconPos == 'left' && icon }"
			[id]="inputSignature"
			[formControlName]="controlName"
			[name]="controlName"
			[type]="'text'"
			[autocomplete]="autoComplete"
			[readOnly]="readOnly"
			[required]="required"
			[spellcheck]="spellCheck"
			[placeholder]="placeholder"
			(change)="onChange($event)"
			(ngModelChange)="onChange($event)"
			(input)="onInput($event)"
		/>

		<label
			*ngIf="['radio', 'checkbox'].includes(inputType)"
			class="flex items-center gap-2 h-[2.375rem] w-full select-none radio-check"
			[ngClass]="{ 'pointer-events-none': !labelClick, 'cursor-pointer': labelClick }"
		>
			<div
				*ngIf="leftLabel"
				class="text-sm"
				[ngClass]="{ 'pointer-events-none': !labelClick, 'cursor-pointer': labelClick }"
			>
				{{ leftLabel }}
			</div>
			<input
				[type]="inputType"
				[class]="class"
				[ngClass]="{ 'form-radio': inputType == 'radio', 'form-checkbox': inputType == 'checkbox', 'text-primary-500': true }"
				[id]="inputSignature"
				[formControlName]="controlName"
				[name]="controlName"
				[readOnly]="readOnly"
				[required]="required"
				[value]="value"
				[checked]="value == formGroup.controls[controlName].value"
				(change)="onChange($event)"
			/>
			<div
				*ngIf="rightLabel"
				class="text-sm"
				[ngClass]="{ 'pointer-events-none': !labelClick, 'cursor-pointer': labelClick }"
			>
				{{ rightLabel }}
			</div>
		</label>

		<label
			*ngIf="inputType == 'switch'"
			class="flex items-center gap-2 h-[2.375rem] w-full select-none"
			[ngClass]="{ 'pointer-events-none': !labelClick, 'cursor-pointer': labelClick }"
		>
			<div
				*ngIf="leftLabel"
				class="text-sm text-slate-400 italic"
				[ngClass]="{ 'pointer-events-none': !labelClick, 'cursor-pointer': labelClick }"
			>
				{{ leftLabel }}
			</div>
			<div class="form-switch pointer-events-auto">
				<input
					[id]="inputSignature"
					[formControlName]="controlName"
					[name]="controlName"
					[checked]="formGroup.get(controlName)?.value"
					[readOnly]="readOnly"
					[required]="required"
					(change)="onChange($event)"
					type="checkbox"
					class="sr-only"
				/>
			</div>
			<div
				*ngIf="rightLabel"
				class="text-sm text-slate-400 italic"
				[ngClass]="{ 'pointer-events-none': !labelClick, 'cursor-pointer': labelClick }"
			>
				{{ rightLabel }}
			</div>
		</label>

		<div
			*ngIf="icon && inputType != 'switch'"
			class="absolute top-0 flex items-center justify-center h-[2.375rem] w-[2.375rem] pointer-events-none"
			[ngClass]="{ 'right-0': iconPos == 'right', 'left-0': iconPos == 'left' }"
		>
			<i
				*ngIf="!loadingState"
				class="fa-duotone text-slate-400"
				[ngClass]="icon"
				style="font-size: 1.2rem"
			></i>
			<svg
				*ngIf="loadingState"
				viewBox="0 0 16 16"
				class="animate-spin w-4 h-4 fill-slate-400 shrink-0"
			>
				<path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
			</svg>
		</div>
	</div>

	<div
		*ngIf="generalError && formGroup.get(controlName)?.invalid && (formGroup.get(controlName)?.dirty || formGroup.get(controlName)?.touched)"
		class="text-xs mt-1 text-rose-500"
	>
		{{ generalError ? generalError : 'El valor no es correcto' }}
	</div>
</div>
