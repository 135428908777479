import { AfterViewInit, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import SwiperCore, { SwiperOptions, Pagination, Navigation, Scrollbar, A11y } from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
	selector: 'app-swiper',
	templateUrl: './swiper.component.html',
	styleUrls: ['./swiper.component.scss']
})
export class SwiperComponent implements AfterViewInit {
	//DOCS: https://v8.swiperjs.com/swiper-api#parameters

	@ContentChildren(TemplateRef) slideTemplates!: QueryList<TemplateRef<any>>;

	@Input() config!: any;

	constructor() {}

	ngAfterViewInit(): void {}

	remToPixels(rem: number) {
		return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
	}

	onSwiper(swiper: any) {
		//console.log(swiper);
	}

	onSlideChange() {
		//console.log('slide change');
	}
}
