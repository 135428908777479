import { ApplicationRef, ComponentRef, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { ModalActionComponent, ModalActionData } from './modal-action.component';

@Injectable({
	providedIn: 'root'
})
export class ModalActionService {
	private dialogRef!: ComponentRef<ModalActionComponent>;
	private viewContainerRef!: ViewContainerRef;

	constructor(
		private injector: Injector,
		private appRef: ApplicationRef
	) {
		this.viewContainerRef = this.injector.get('APP_VIEW_CONTAINER_REF', null);
	}

	openDialog(config: ModalActionData = {}): Promise<any> {
		return new Promise((resolve, reject) => {
			// Crear componente dinámicamente
			this.dialogRef = this.viewContainerRef.createComponent(ModalActionComponent);

			// Pasar la configuración al componente
			this.dialogRef.instance.config = {
				...this.dialogRef.instance.config,
				...config
			};

			// Abrir modal
			this.dialogRef.instance.open();

			// Manejar el cierre del diálogo
			this.dialogRef.instance.onClose.subscribe((result: any) => {
				this.appRef.detachView(this.dialogRef.hostView);
				this.dialogRef.destroy();
				resolve(result);
			});
		});
	}
}
