import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SmartChatService {
	constructor(private http: HttpClient) {}

	environment = {
		apiBase: 'http://localhost:3000/'
	};

	// Crear Nuevo Chat
	create(): Observable<any> {
		return this.http.post(`${this.environment.apiBase}v1/chat/create`, {});
	}

	// Obtener Chat
	getThread(threadId: string): Observable<any> {
		return this.http.get(`${this.environment.apiBase}v1/chat/thread/${threadId}`);
	}

	// Enviar mensaje
	message(threadId: string, assistantId: string, message: string, messageId: string): Observable<any> {
		return this.http.post(`${this.environment.apiBase}v1/chat/message/${threadId}/${assistantId}`, {
			uuid: messageId,
			message: message
		});
	}

	// Comprobar estado del Run
	getRunStatus(runId: string, threadId: string, assistantId: string): Observable<any> {
		return this.http.get(`${this.environment.apiBase}v1/chat/run/${runId}/${threadId}/${assistantId}`);
	}
}
