<div
	class="relative form-input flex items-center justify-center w-full h-full !p-0"
	[ngClass]="{ 'pointer-events-none': disable }"
>
	<button
		*ngIf="!disable && currentImage"
		(click)="cancelImage()"
		type="button"
		class="absolute -top-3 -right-3 z-[1] flex bg-rose-500 hover:bg-rose-600 text-white rounded-full"
		ngxTippy
		data-tippy-content="Cancelar"
	>
		<i
			class="fa-duotone fa-circle-minus text-white"
			style="font-size: 1.5rem; --fa-secondary-opacity: 0"
		></i>
	</button>
	<button
		*ngIf="!disable && originalImage && !currentImage"
		(click)="clearImage()"
		type="button"
		class="absolute -top-3 -right-3 z-[1] flex bg-rose-500 hover:bg-rose-600 text-white rounded-full"
		ngxTippy
		data-tippy-content="Eliminar"
	>
		<i
			class="fa-duotone fa-circle-xmark text-white"
			style="font-size: 1.5rem; --fa-secondary-opacity: 0"
		></i>
	</button>
	<label class="absolute cursor-pointer left-0 top-0 w-full h-full flex items-center justify-center transition-all opacity-0 hover:opacity-100 hover:bg-[#e2e8f0] hover:bg-opacity-60">
		<div class="btn text-slate-600">
			<div class="w-6 h-6 grid mr-2 place-content-center">
				<i
					class="fa-duotone fa-arrow-up-from-square"
					style="font-size: 1rem"
				></i>
			</div>
			Cargar
		</div>
		<input
			type="file"
			class="hidden"
			(change)="fileChangeEvent($event)"
		/>
	</label>
	<img
		class="w-full h-full object-cover rounded-sm"
		[src]="currentImage ? currentImage : originalImage ? originalImage : placeHolderImage"
		[alt]="altText"
	/>
</div>

<app-image-editor-overlay
	#imageEditorOverlay
	[imageChangedEvent]="imageChangedEvent"
	[aspectRatio]="imageAspectRatio"
	(onCroppedEvent)="onCroppedImage($event)"
	(onCancelEvent)="onCroppedCancel($event)"
	(onSaveEvent)="onCroppedSave($event)"
>
</app-image-editor-overlay>
