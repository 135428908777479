<ng-template
	appModalOverlay
	#modalActionOverlay="appModalOverlay"
	[closeOnEscape]="config.closeOnEscape || false"
	[closeBackdropClick]="config.closeBackdropClick || false"
	(onDetach)="cancel()"
>
	<div class="bg-white rounded shadow-lg overflow-hidden w-[32rem] max-w-[90vw] max-h-[90dvh] m-auto flex flex-col">
		<div class="px-5 py-3 border-b bg-white border-slate-200 relative top-0">
			<div class="flex justify-between items-center">
				<div class="text-lg font-semibold text-primary-100">
					{{ config.title }}
				</div>

				<button class="text-slate-400 hover:text-slate-500 close-overlay-directive">
					<div class="sr-only">Cerrar</div>
					<svg class="w-4 h-4 fill-current">
						<path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"></path>
					</svg>
				</button>
			</div>
		</div>
		<div class="p-4 relative border-b">
			<div
				*ngIf="config.icon == 'warning'"
				class="w-full text-center mb-5"
			>
				<i
					class="fa-duotone fa-triangle-exclamation"
					style="font-size: 6rem; --fa-primary-color: #fff; --fa-secondary-color: #f89406; --fa-secondary-opacity: 1"
				></i>
			</div>
			<div
				*ngIf="config.icon == 'info'"
				class="w-full text-center mb-5"
			>
				<i
					class="fa-duotone fa-circle-info"
					style="font-size: 6rem; --fa-primary-color: #fff; --fa-secondary-color: #2f96b4; --fa-secondary-opacity: 1"
				></i>
			</div>
			<div
				*ngIf="config.icon == 'success'"
				class="w-full text-center mb-5"
			>
				<i
					class="fa-duotone fa-circle-check"
					style="font-size: 6rem; --fa-primary-color: #fff; --fa-secondary-color: #51a351; --fa-secondary-opacity: 1"
				></i>
			</div>
			<div
				*ngIf="config.icon == 'error'"
				class="w-full text-center mb-5"
			>
				<i
					class="fa-duotone fa-circle-xmark"
					style="font-size: 6rem; --fa-primary-color: #fff; --fa-secondary-color: #bd362f; --fa-secondary-opacity: 1"
				></i>
			</div>
			<div class="text-center">
				<div class="text-lg text-primary-100">
					{{ config.message }}
				</div>
			</div>
		</div>
		<div class="py-5 px-4 border-t bg-pearl border-slate-200 sticky bottom-0">
			<div class="flex flex-wrap justify-end space-x-2">
				<button class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600 close-overlay-directive">Cancelar</button>
				<button
					class="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white close-overlay-directive"
					(click)="confirm()"
				>
					Aceptar
				</button>
			</div>
		</div>
	</div>
</ng-template>
