import { Directive, Input, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: '[lazyImage]'
})
export class LazyImageDirective implements OnInit {
	@Input('lazyImage') imageUrl!: string;
	@Input('imageType') imageType: string = 'src';

	imageLoaded: boolean = false;

	constructor(
		private elementRef: ElementRef,
		private renderer: Renderer2
	) {}

	ngOnInit() {
		this.loadImage();
	}

	loadImage() {
		const image = new Image();
		image.src = this.imageUrl;
		image.onload = () => {
			if (this.imageType == 'background-image') {
				console.log(this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', `url(${this.imageUrl})`));
				this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', `url(${this.imageUrl});`);
			} else {
				this.elementRef.nativeElement.src = this.imageUrl;
			}
			this.imageLoaded = true;
			this.showHideElement();
		};
	}

	showHideElement() {
		const elementToHide = this.elementRef.nativeElement.getAttribute('data-hide-element');
		if (elementToHide) {
			const element = document.querySelector(elementToHide);
			if (element) {
				if (this.imageLoaded) {
					element.style.display = 'none';
				} else {
					element.style.display = 'block';
				}
			}
		}
	}
}
