import { Component, ElementRef, HostListener, QueryList, ViewChildren } from '@angular/core';
import { DevToolService } from '../../directives/dev-tool.service';

@Component({
	selector: 'app-dev-tools',
	templateUrl: './dev-tools.component.html',
	styleUrl: './dev-tools.component.scss'
})
export class DevToolsComponent {
	@ViewChildren('debugArea') debugAreas: QueryList<ElementRef> | undefined;

	debugActive: boolean = false;

	constructor() {}

	/* Al presionar escape cerrar el sidebar */
	@HostListener('document:keydown.f9', ['$event'])
	@HostListener('document:touchstart', ['$event'])
	onKeydownHandler($event: any) {
		if ($event.type == 'touchstart') {
			if ($event.touches.length > 5) this.debugActive = !this.debugActive;
			return;
		}

		this.debugActive = !this.debugActive;
	}

	get devTools() {
		return DevToolService.tools;
	}

	toolDragStarted(event: any) {
		this.debugAreas?.forEach((element) => {
			(element.nativeElement as HTMLElement).style.zIndex = '';
		});

		const element = (event.source.element.nativeElement as HTMLElement).parentNode as HTMLElement;
		element.style.zIndex = String(2000 + 1);
	}

	toolClicked(event: any) {
		this.debugAreas?.forEach((element) => {
			(element.nativeElement as HTMLElement).style.zIndex = '';
		});

		const element = event.parentNode as HTMLElement;
		element.style.zIndex = String(2000 + 1);
	}
}
