import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { layoutData, venueData } from '../../interfaces/services-data';

@Injectable({
	providedIn: 'root'
})
export class VenuesService {
	constructor(private http: HttpClient) {}

	/* Crear Venue */
	create(data: venueData): Observable<any> {
		return this.http.post(`${environment.apiBase}v1/venue/create`, data);
	}

	/* Actualizar Venue */
	update(data: venueData, venueId: number): Observable<any> {
		return this.http.put(`${environment.apiBase}v1/venue/${venueId}/update`, data);
	}

	/* Crear Layout */
	layoutCreate(data: layoutData): Observable<any> {
		return this.http.post(`${environment.apiBase}v1/layout/create`, data);
	}

	/* Actualizar Layout */
	layoutUpdate(data: layoutData, layoutId: number): Observable<any> {
		return this.http.put(`${environment.apiBase}v1/layout/${layoutId}/update`, data);
	}

	/* Crear Imagenes */
	imageCreate(data: any[]) {
		const formData = new FormData();

		data.forEach((media, index) => {
			const file = this.__dataURIToBlob(media.base64);
			formData.append('array_files', file, media.name);
			formData.append(`media[${index}][name]`, media.name);
			formData.append(`media[${index}][venue_id]`, media.venue_id);
			formData.append(`media[${index}][subcategory_id]`, media.subcategory_id);
		});

		return this.http.post(`${environment.apiBase}v1/venue/images/create`, formData);
	}

	private __dataURIToBlob(dataURI: string) {
		const splitDataURI = dataURI.split(',');
		const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	}

	private __blobTodataURI(blob: Blob) {
		return new Promise((r) => {
			let a = new FileReader();
			a.onload = r;
			a.readAsDataURL(blob);
		}).then((e: any) => e.target.result);
	}
}
