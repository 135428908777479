import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DevToolService {

    // Todos los tools registrados
    static tools: { uuid: string, toolName: string, hostComponent: string, template: TemplateRef<any> }[] = [];
    static toolRegistered = new Subject<any>(); // Este Subject notificará cuando se registre una nueva herramienta

    // Registrar tool
    static registerTool(uuid: string, toolName: string, hostComponent: string, template: TemplateRef<any>): void {

        const tool = {
            uuid: uuid,
            toolName: toolName,
            hostComponent: hostComponent,
            template: template
        };

        this.tools.push(tool);
        this.toolRegistered.next({});
    }

    // Desregistrar tool
    static unregisterTool(uuid: string): void {
        this.tools.splice(this.tools.findIndex(tool => tool.uuid == uuid));
        this.toolRegistered.next({});
    }
}