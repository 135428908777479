<nav
	class="flex mb-4 sm:mb-0 sm:order-1"
	[ngClass]="{ 'pointer-events-none opacity-50': disabled }"
>
	<div class="mr-2">
		<button
			[disabled]="page == 1"
			(click)="prevPage()"
			[ngClass]="page == 1 ? 'text-slate-300' : 'text-slate-600 hover:text-white hover:bg-indigo-500'"
			class="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white border border-slate-200 shadow-sm"
		>
			<span class="sr-only">Previous</span><wbr />
			<svg
				class="h-4 w-4 fill-current"
				viewBox="0 0 16 16"
			>
				<path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z"></path>
			</svg>
		</button>
	</div>
	<ul class="inline-flex text-sm font-medium -space-x-px shadow-sm">
		<ng-container *ngFor="let p of [].constructor(totalPages); let i = index">
			<li *ngIf="canShowPage(i + 1)">
				<button
					(click)="setPage(i + 1)"
					[ngClass]="[page == i + 1 ? 'text-indigo-500' : 'text-slate-600 hover:bg-indigo-500 hover:text-white', i + 1 == 1 ? 'first:rounded-l' : '', i + 1 == totalPages ? 'first:rounded-r' : '']"
					class="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white border border-slate-200"
				>
					{{ i + 1 }}
				</button>
			</li>
		</ng-container>
	</ul>
	<div class="ml-2">
		<button
			[disabled]="page == totalPages"
			(click)="nextPage()"
			[ngClass]="page == totalPages ? 'text-slate-300' : 'text-slate-600 hover:text-white hover:bg-indigo-500'"
			class="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white border border-slate-200 shadow-sm"
		>
			<span class="sr-only">Next</span><wbr />
			<svg
				class="h-4 w-4 fill-current"
				viewBox="0 0 16 16"
			>
				<path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z"></path>
			</svg>
		</button>
	</div>
</nav>
