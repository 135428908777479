import { Directive, TemplateRef, Self, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[appModalOverlay]',
	exportAs: 'appModalOverlay'
})
export class ModalOverlayDirective {
	@Input() closeOnEscape: boolean = true;
	@Input() closeBackdropClick: boolean = true;
	@Input() templateContext: any = {};

	@Output() onDetach = new EventEmitter<boolean>();

	overlayRef: any;
	templateElement!: HTMLElement;

	constructor(
		private elementRef: ElementRef,
		private overlay: Overlay,
		private viewContainerRef: ViewContainerRef,
		@Self() private overlayTemplateRef: TemplateRef<any>
	) {}

	/**
	 * Abrir overlay
	 */
	open() {
		let overlayConfig = this.overlay.position().global().centerHorizontally().centerVertically();

		this.overlayRef = this.overlay.create(
			new OverlayConfig({
				hasBackdrop: true
			})
		);

		console.log(this.templateContext);

		this.overlayRef.attach(new TemplatePortal(this.overlayTemplateRef, this.viewContainerRef, this.templateContext));
		this.overlayRef.addPanelClass('custom-overlay-panel');
		this.overlayRef.updatePositionStrategy(overlayConfig);

		// Agregar el evento para cerrar el overlay al hacer click al backdrop
		if (this.closeBackdropClick) {
			this.overlayRef.backdropClick().subscribe(() => {
				this.close();
			});
		}

		// Agregar el evento keydown al documento para cerrar el overlay al presionar 'Escape'
		if (this.closeOnEscape) {
			document.addEventListener('keydown', this.onScapeDown);
		}

		// Agregar evento close a los botones con la clase
		this.overlayRef._pane.querySelectorAll('.close-overlay-directive').forEach((button: HTMLElement) => {
			button.addEventListener('click', () => {
				this.close();
			});
		});

		// Notificar cuando se elimine el overlay
		this.overlayRef.detachments().subscribe(() => {
			this.close();
		});
	}

	/**
	 * Establecer contexto del template
	 * @param context
	 */
	setContext(context: any) {
		this.templateContext = context;
	}

	/**
	 * Cerrar overlay al pulsar Escape
	 */
	onScapeDown = (event: any) => {
		if (event.key === 'Escape') {
			this.close();
		}
	};

	/**
	 * Cerrar overlay
	 */
	close() {
		this.overlayRef.detach();
		this.overlayRef.dispose();
		this.onDetach.emit(true);
	}

	/**
	 * Esta abierto?
	 * @returns open: boolean
	 */
	isOpen(): boolean {
		return this.overlayRef ? true : false;
	}
}
