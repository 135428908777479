import { CdkAccordionItem } from '@angular/cdk/accordion';
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
	selector: 'app-function-zone-inventory',
	templateUrl: './function-zone-inventory.component.html',
	styleUrls: ['./function-zone-inventory.component.scss']
})
export class FunctionZoneInventoryComponent {
	@Input() absoluteContent: boolean = false;
	@ViewChild('accordionItem') accordionItem!: CdkAccordionItem;

	@HostListener('document:click', ['$event'])
	clickout(event: any) {
		if (!this.eRef.nativeElement.contains(event.target)) {
			if (this.accordionItem.expanded) {
				this.accordionItem.toggle();
			}
		}
	}

	constructor(private eRef: ElementRef) {}
}
