import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class FirebaseMessagingService {
	static app: firebase.app.App;
	static messaging: firebase.messaging.Messaging;
	static firebaseDeviceToken: string = '';
	static firebaseDeviceToken$: BehaviorSubject<string> = new BehaviorSubject<string>(FirebaseMessagingService.firebaseDeviceToken);

	/**
	 * Inicia la configuración de firebase
	 */
	static async initialize() {
		// Inicializar firebase
		this.app = firebase.initializeApp(environment.firebaseConfig);
		this.messaging = this.app.messaging();

		// Mantener actualizado el token de notificación
		// Enviar token a los dispositivos del usaurio
		this.firebaseDeviceToken$.subscribe((token: string) => {
			this.firebaseDeviceToken = token;
		});

		// Si estan activas las notificaciones y hay sesion iniciada
		// se hace polling para obtener el token
		if (this.activePushNotification) {
			FirebaseMessagingService.pollingRequestPushToken();
		}
	}

	/**
	 * Solicita el permiso de notificación al usaurio y emite un token de dispositivo para firebase
	 * @returns
	 */
	static requestNotificationPermission() {
		return new Promise(async (resolve, reject) => {
			const permissions = await Notification.requestPermission();

			if (permissions === 'granted') {
				this.pollingRequestPushToken();
				resolve(permissions);
			} else {
				reject(new Error(permissions));
			}
		});
	}

	/**
	 * Realiza polling hasta obtener el token de notificación
	 */
	static async pollingRequestPushToken() {
		try {
			await this.requestPushToken();
		} catch (error) {
			FirebaseMessagingService.pollingRequestPushToken();
		}
	}

	/**
	 * Si el service worker de firebase esta activo y hay permisos de notificación
	 * se solicita el token de firebase
	 * @returns
	 */
	static async requestPushToken() {
		if (this.activePushNotification && !this.firebaseDeviceToken) {
			const token = await this.messaging.getToken();
			this.firebaseDeviceToken$.next(token);
			console.log(token);
		}

		return this.activePushNotification ? true : false;
	}

	/**
	 * Elimina el token de firebase
	 */
	static async clearPushToken() {
		await this.messaging.deleteToken();
		this.firebaseDeviceToken$.next('');
	}

	/**
	 * Verifica si las notificaciones son activadas
	 */
	static get activePushNotification(): boolean {
		return Notification.permission === 'granted' ? true : false;
	}
}
