import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ImageEditorOverlayComponent } from '../image-editor-overlay/image-editor-overlay.component';

@Component({
	selector: 'app-image-uploader',
	templateUrl: './image-uploader.component.html',
	styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {
	@Input() originalImage: string | null = null;
	@Input() imageAspectRatio: number = 0;
	@Input() altText: string = 'image';
	@Input() placeHolderImage: string = 'assets/images/general/venue-placeholder.png';
	@Input() disable: boolean = false;

	@Output() onSaveEvent = new EventEmitter<string | null>();

	@ViewChild('imageEditorOverlay', { read: ImageEditorOverlayComponent })
	imageEditor!: ImageEditorOverlayComponent;

	currentImage: string | null = null;
	imageChangedEvent: any = '';

	constructor() {}

	cancelImage() {
		this.currentImage = null;
		if (this.originalImage) {
			this.onSaveEvent.emit(this.originalImage);
		} else {
			this.onSaveEvent.emit(this.currentImage);
		}
	}

	clearImage() {
		this.originalImage = null;
		this.onSaveEvent.emit(this.originalImage);
	}

	fileChangeEvent(event: any) {
		/* Open image editor overlay */
		this.imageChangedEvent = event;
		this.imageEditor.openOverlay();

		setTimeout(() => {
			event.target.value = null;
		}, 1000);
	}

	onCroppedImage(event: any) {
		this.currentImage = event;
	}

	onCroppedSave(event: any) {
		this.onSaveEvent.emit(this.currentImage);
	}

	onCroppedCancel(event: any) {
		this.cancelImage();
	}
}
