import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-goliiive-seats-io-seating-chart',
	templateUrl: './goliiive-seats-io-seating-chart.component.html',
	styleUrls: ['./goliiive-seats-io-seating-chart.component.scss']
})
export class GoliiiveSeatsIoSeatingChartComponent implements OnDestroy, OnInit, AfterViewInit {
	@Input() workspaceKey: string = '5e28dc45-0ba2-4387-bb38-0bffea1d3212';
	@Input() mapToken: any = null;
	@Input() event: string = '';
	@Input() availableCategories: (string | null)[] = [];
	@Input() maxSelectedObjects: any[] | undefined = undefined;

	@Output() onObjectSelected = new EventEmitter<any>();
	@Output() onObjectDeselected = new EventEmitter<any>();
	@Output() onSessionInitialized = new EventEmitter<any>();

	renderChart: boolean = false;
	chart: any = null;

	// Configuración y eventos Seats.io
	config: any = {
		session: 'manual',
		language: 'es',
		region: 'na',
		onChartRerenderingStarted: this.__onChartRerenderingStarted.bind(this),
		onChartRenderingFailed: this.__onChartRenderingFailed.bind(this),
		onChartRendered: this.__onChartRendered.bind(this),
		onObjectClicked: this.__onObjectClicked.bind(this),
		onObjectSelected: this.__onObjectSelected.bind(this),
		onObjectDeselected: this.__onObjectDeselected.bind(this),
		onObjectMouseOver: this.__onObjectMouseOver.bind(this),
		onObjectMouseOut: this.__onObjectMouseOut.bind(this),
		onObjectStatusChanged: this.__onObjectStatusChanged.bind(this),
		onSelectedObjectBooked: this.__onSelectedObjectBooked.bind(this),
		onSessionInitialized: this.__onSessionInitialized.bind(this),
		onHoldSucceeded: this.__onHoldSucceeded.bind(this),
		onHoldFailed: this.__onHoldFailed.bind(this),
		onHoldTokenExpired: this.__onHoldTokenExpired.bind(this),
		onReleaseHoldSucceeded: this.__onReleaseHoldSucceeded.bind(this),
		onReleaseHoldFailed: this.__onReleaseHoldFailed.bind(this),
		onSelectionValid: this.__onSelectionValid.bind(this),
		onSelectionInvalid: this.__onSelectionInvalid.bind(this),
		onFullScreenOpened: this.__onFullScreenOpened.bind(this),
		onFullScreenClosed: this.__onFullScreenClosed.bind(this),
		onFilteredCategoriesChanged: this.__onFilteredCategoriesChanged.bind(this),
		onFloorChanged: this.__onFloorChanged.bind(this)
	};

	constructor() {
		// Añadir el resto de configuraciones en el constructor y renderizar el mapa
		this.setSeatsPrimarySettings();
	}

	ngOnDestroy(): void {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {}

	// Añadir el resto de configuraciones en el constructor y renderizar el mapa
	setSeatsPrimarySettings() {
		//console.log('setSeatsPrimarySettings');

		// Dado que el evento y el workspace son llamados por api
		// se debe ejecutar esta funcion de manera asincrona
		// talvez migrar a un evento con reducers
		setTimeout(() => {
			// Si las opciones esenciales estan disponibles
			if ((this.workspaceKey, this.event)) {
				this.config.workspaceKey = this.workspaceKey;
				this.config.holdToken = this.mapToken.holdToken;
				this.config.event = this.event;
				this.config.availableCategories = this.availableCategories;
				this.config.maxSelectedObjects = this.maxSelectedObjects;

				// Habilitar la renderización del mapa
				// Al estar esta variable en true el bloque del mapa
				// se muestra por lo que el mapa comienza su proceso de inicialización
				this.renderChart = true;
			}
		}, 100);
	}

	//////////////////////////////////// Seats.io Methods

	startNewSession(): void {
		this.chart.startNewSession();
	}

	//////////////////////////////////// Seats.io Events

	__onChartRerenderingStarted(chart: any) {
		//console.log('onChartRerenderingStarted:');
		//console.log(chart);
	}

	__onChartRenderingFailed(chart: any) {
		//console.log('onChartRenderingFailed:');
		//console.log(chart);
	}

	__onChartRendered(chart: any) {
		//console.log('onChartRendered:');
		//console.log(chart);

		this.chart = chart;
	}

	__onObjectClicked(object: any) {
		//console.log('onObjectClicked:');
		//console.log(object);
	}

	__onObjectSelected(object: any, selectedTicketType: any) {
		//console.log('onObjectSelected:');
		//console.log(object);
		//console.log(selectedTicketType);

		this.onObjectSelected.emit({
			object: object,
			selectedTicketType: selectedTicketType
		});
	}

	__onObjectDeselected(object: any, deselectedTicketType: any) {
		console.log('onObjectDeselected:');
		console.log(object);
		console.log(deselectedTicketType);

		this.onObjectDeselected.emit({
			object: object,
			selectedTicketType: deselectedTicketType
		});
	}

	__onObjectMouseOver(object: any) {
		//console.log('onObjectMouseOver:');
		//console.log(object);
	}

	__onObjectMouseOut(object: any) {
		//console.log('onObjectMouseOut:');
		//console.log(object);
	}

	__onObjectStatusChanged(object: any) {
		//console.log('onObjectStatusChanged:');
		//console.log(object);
	}

	__onSelectedObjectBooked(object: any) {
		//console.log('onSelectedObjectBooked:');
		//console.log(object);
	}

	__onSessionInitialized(holdToken: any) {
		this.onSessionInitialized.emit({
			holdToken: holdToken
		});
	}

	__onHoldSucceeded(objects: any, ticketTypes: any) {
		//console.log('onHoldSucceeded:');
		//console.log(objects);
		//console.log(ticketTypes);
	}

	__onHoldFailed(objects: any, ticketTypes: any) {
		//console.log('onHoldFailed:');
		//console.log(objects);
		//console.log(ticketTypes);
	}

	__onHoldTokenExpired() {
		//console.log('onHoldTokenExpired:');
	}

	__onReleaseHoldSucceeded(objects: any, ticketTypes: any) {
		//console.log('onReleaseHoldSucceeded:');
		//console.log(objects);
		//console.log(ticketTypes);
	}

	__onReleaseHoldFailed(objects: any, ticketTypes: any) {
		//console.log('onReleaseHoldFailed:');
		//console.log(objects);
		//console.log(ticketTypes);
	}

	__onSelectionValid() {
		//console.log('onSelectionValid:');
	}

	__onSelectionInvalid(violations: any) {
		//console.log('onSelectionInvalid:');
		//console.log(violations);
	}

	__onFullScreenOpened() {
		//console.log('onFullScreenOpened:');
	}

	__onFullScreenClosed() {
		//console.log('onFullScreenClosed:');
	}

	__onFilteredCategoriesChanged(array: any) {
		//console.log('onFilteredCategoriesChanged:');
		//console.log(array);
	}

	__onFloorChanged(floor: any) {
		//console.log('onFloorChanged:');
		//console.log(floor);
	}
}
