import { Directive, ElementRef, Input, OnInit, OnDestroy, Injectable, afterNextRender, NgZone } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Directive({
	selector: '[appParallaxEffect]'
})
@Injectable({
	providedIn: 'root'
})
export class ParallaxEffectDirective implements OnInit, OnDestroy {
	@Input('parallaxConfig') parallaxConfig: any;
	@Input('parallaxType') parallaxType: 'element' | 'css-vars' = 'element';

	private parent: any;
	private eventSignature: string;
	private currentX: number = 0;
	private currentY: number = 0;
	private targetX: number = 0;
	private targetY: number = 0;
	private frameId: number | null = null;

	/* Inicializar directiva */
	constructor(
		private elementRef: ElementRef,
		private zone: NgZone
	) {
		this.eventSignature = 'parallax-' + uuidv4();

		/* ClientSide Code */
		afterNextRender(() => {
			this.zone.runOutsideAngular(() => {
				this.setupEventListener();
			});
		});
	}

	ngOnInit() {}

	/* Finalizar directiva */
	ngOnDestroy() {
		if (this.parent) {
			this.parent.removeEventListener('mousemove', this.parent[this.eventSignature]);
		}

		if (this.frameId) {
			cancelAnimationFrame(this.frameId);
		}
	}

	/* Configurar el event listener */
	setupEventListener() {
		/* Se obtiene el elemento padre */
		/* Este elemento es quien define el area del efecto */
		/* Elementos padres locales limitan el efecto al area propia */
		/* Lo ideal siempre seria enviar el body al menos que se desee */
		/* Limitar el area del paralaje */
		this.parent = document.querySelector(this.parallaxConfig.parentSelector);

		/* Hay que declarar la funcion que ejecutara el event listener como */
		/* una propiedad del elemento, ya que si posteriormente removemos */
		/* el event listener utilizado en esta directiva (mousemove) se corre */
		/* el riesgo de eliminar otros listener similares anonimos, de esta */
		/* forma solo se elimina el actual */
		this.parent[this.eventSignature] = (e: MouseEvent) => {
			/* Guardar en localstorage la cache del evento asociado con el padre */
			localStorage.setItem(`p-mouse-pos[${this.parent}]`, JSON.stringify({ x: e.clientX, y: e.clientY }));

			const ow = this.normalizeNP(e.clientX, this.parent.offsetWidth, 0);
			const oh = this.normalizeNP(e.clientY, this.parent.offsetHeight, 0);

			this.targetX = ow * (this.parallaxConfig.directX * this.parallaxConfig.offsetX);
			this.targetY = oh * (this.parallaxConfig.directY * this.parallaxConfig.offsetY);

			if (!this.frameId) {
				this.frameId = requestAnimationFrame(() => this.updatePosition());
			}
		};

		this.parent.addEventListener('mousemove', this.parent[this.eventSignature]);
	}

	/* Actualizar con interpolacion suave */
	updatePosition() {
		// Linear interpolation
		const lerp = (start: number, end: number, amt: number) => start * (1 - amt) + end * amt;

		// Interpolation factor, determines the smoothness
		const lerpFactor = 0.05;

		this.currentX = lerp(this.currentX, this.targetX, lerpFactor);
		this.currentY = lerp(this.currentY, this.targetY, lerpFactor);

		const nw = this.currentX + '%';
		const nh = this.currentY + '%';

		if (this.parallaxType === 'css-vars') {
			this.setCssProp(this.elementRef, '--parallax-x', nw);
			this.setCssProp(this.elementRef, '--parallax-y', nh);
		} else {
			this.setCssProp(this.elementRef, 'transform', `translate(${nw}, ${nh})`);
		}

		this.frameId = requestAnimationFrame(() => this.updatePosition());
	}

	/* Normalizar un valor [val] a una escala [max] [min] */
	/* https://stackoverflow.com/questions/39776819/function-to-normalize-any-number-from-0-1 */
	/* Ajustado para funcionar mejor con funciones basadas en paralaje */
	normalizeNP(val: any, max: any, min: any) {
		return 2 * ((val - min) / (max - min)) - 1;
	}

	/* Establecer propiedades de css */
	setCssProp(ele: ElementRef, propName: string, propValue: string | number) {
		ele.nativeElement.style.setProperty(propName, propValue);
	}
}
