<div
	class="absolute shadow-lg overflow-hidden max-h-[calc(100svh-4rem)] sm:max-h-[calc(100svh-6rem)] transition-all"
	[ngClass]="{
		'w-full h-full right-0 bottom-0 rounded-none sm:border sm:border-slate-200 sm:right-8 sm:bottom-8 sm:rounded-xl sm:h-[80vh] sm:w-[70svw] xl:w-[60svw] 2xl:w-[55svw]': !this.componentStates.close,
		'!w-16 !h-16 !right-8 !bottom-8 !rounded-full !border !border-slate-200': this.componentStates.close,
		'sm:!w-full sm:!h-full sm:!right-0 sm:!bottom-0 !rounded-none !border-none !max-h-[calc(100svh-4rem)]': this.componentStates.maximize
	}"
>
	<div class="relative w-full h-full">
		<div
			(click)="open()"
			ngxTippy
			[ngxTippy]="'Conversación con Inteligencia Artificial'"
			[tippyProps]="{ placement: 'auto', allowHTML: true, hideOnClick: true }"
			class="absolute w-full h-full top-0 left-0 bg-white z-[1] transition-all flex items-center justify-center cursor-pointer select-none"
			[ngClass]="this.componentStates.close ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'"
		>
			<img
				class="w-10 h-10 rounded-full"
				src="https://dev.midespacho.cloud/assets/images/isotipo.png"
				alt="MiDespacho Chat Logo"
			/>
		</div>
		<div class="absolute w-full h-full top-0 left-0">
			<div class="relative flex h-full bg-slate-100">
				<!-- Messages sidebar -->
				<div
					class="absolute top-0 bottom-0 left-0 h-full w-full sm:w-auto sm:static sm:top-auto sm:bottom-auto -mr-px transition-all"
					[ngClass]="{ '-translate-x-full': !componentStates.threadsMenu }"
				>
					<div class="sticky bg-white overflow-hidden shrink-0 border-r border-slate-200 sm:w-72 xl:w-80 h-full">
						<!-- #Marketing group -->
						<div class="h-full flex flex-col">
							<!-- Group header -->
							<div class="sticky top-0 z-10">
								<div class="flex items-center bg-white border-b border-slate-200 px-5 h-16">
									<div class="w-full flex items-center justify-between">
										<!-- Channel menu -->
										<div
											class="relative"
											x-data="{ open: false }"
										>
											<button class="grow flex items-center truncate">
												<img
													class="w-8 h-8 rounded-full mr-2"
													src="https://dev.midespacho.cloud/assets/images/isotipo.png"
													width="32"
													height="32"
													alt="Group 01"
												/>
												<div class="truncate">
													<span class="font-semibold text-slate-800">Asuntos</span>
												</div>
												<svg
													class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
													viewBox="0 0 12 12"
												>
													<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"></path>
												</svg>
											</button>
											<div
												class="origin-top-right z-10 absolute top-full left-0 min-w-60 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
												x-show="open"
												x-transition:enter="transition ease-out duration-200 transform"
												x-transition:enter-start="opacity-0 -translate-y-2"
												x-transition:enter-end="opacity-100 translate-y-0"
												x-transition:leave="transition ease-out duration-200"
												x-transition:leave-start="opacity-100"
												x-transition:leave-end="opacity-0"
												style="display: none"
											>
												<ul>
													<li>
														<a
															class="font-medium text-sm text-slate-600 hover:text-slate-800 block py-1.5 px-3"
															href="#0"
														>
															<div class="flex items-center justify-between">
																<div class="grow flex items-center truncate">
																	<img
																		class="w-7 h-7 rounded-full mr-2"
																		src="./images/channel-01.png"
																		width="28"
																		height="28"
																		alt="Channel 01"
																	/>
																	<div class="truncate">#Marketing</div>
																</div>
																<svg
																	class="w-3 h-3 shrink-0 fill-current text-indigo-500 ml-1"
																	viewBox="0 0 12 12"
																>
																	<path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"></path>
																</svg>
															</div>
														</a>
													</li>
													<li>
														<a
															class="font-medium text-sm text-slate-600 hover:text-slate-800 block py-1.5 px-3"
															href="#0"
														>
															<div class="flex items-center justify-between">
																<div class="grow flex items-center truncate">
																	<img
																		class="w-7 h-7 rounded-full mr-2"
																		src="./images/channel-02.png"
																		width="28"
																		height="28"
																		alt="Channel 02"
																	/>
																	<div class="truncate">#Developing</div>
																</div>
															</div>
														</a>
													</li>
													<li>
														<a
															class="font-medium text-sm text-slate-600 hover:text-slate-800 block py-1.5 px-3"
															href="#0"
														>
															<div class="flex items-center justify-between">
																<div class="grow flex items-center truncate">
																	<img
																		class="w-7 h-7 rounded-full mr-2"
																		src="./images/channel-03.png"
																		width="28"
																		height="28"
																		alt="Channel 03"
																	/>
																	<div class="truncate">#ProductSupport</div>
																</div>
															</div>
														</a>
													</li>
												</ul>
											</div>
										</div>
										<!-- Edit button -->

										<div class="flex">
											<button
												(click)="createThread()"
												class="w-8 h-8 cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2"
												ngxTippy
												[tippyProps]="{ placement: 'top', hideOnClick: true }"
												[ngxTippy]="'Generar Conversación (Prueba)'"
											>
												<i
													class="fa-duotone fa-plus opacity-75 hover:opacity-100"
													style="font-size: 1rem"
												></i>
											</button>
											<button
												(click)="componentStates.threadsMenu = !componentStates.threadsMenu"
												class="w-8 h-8 cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2"
												ngxTippy
												[tippyProps]="{ placement: 'top', hideOnClick: true }"
												[ngxTippy]="'Ocultar Conversaciones'"
											>
												<i
													class="fa-duotone fa-arrow-left-from-line hover:opacity-100"
													style="font-size: 1rem"
												></i>
											</button>
										</div>
									</div>
								</div>
							</div>
							<!-- Group body -->
							<div class="px-5 py-4 flex-1 flex flex-col relative max-h-full overflow-x-hidden overflow-y-auto no-scrollbar">
								<app-indeterminate-loader
									*ngIf="loadingStates.creatingThread.value"
									[position]="'absolute'"
									[top]="'0'"
									[left]="'0'"
								></app-indeterminate-loader>

								<!-- Search form -->
								<form class="sticky top-0 z-[1]">
									<label
										for="msg-search"
										class="sr-only"
										>Search</label
									>
									<app-input-field-maker
										[label]="false"
										[placeholder]="'Buscar…'"
										[inputType]="'text'"
										[autoComplete]="false"
										[icon]="'fa-magnifying-glass'"
									></app-input-field-maker>
								</form>
								<!-- Conversaciones -->
								<div class="flex-1 flex flex-col max-h-full z-0">
									<div class="mt-4">
										<div
											(click)="clearActiveThread()"
											class="text-xs font-semibold text-slate-400 uppercase mb-3"
										>
											Conversaciones
										</div>
										<div>
											<ul
												class="mb-6"
												*ngIf="threads[0]"
											>
												<li
													class="-mx-2 -intro-x"
													*ngFor="let iThread of threads"
												>
													<button
														(click)="setActiveThread(iThread.threadId)"
														class="flex items-center justify-between w-full p-2 rounded"
														[ngClass]="{ 'bg-indigo-500/30': thread?.threadId == iThread.threadId }"
													>
														<div class="flex items-center truncate">
															<div
																*ngIf="iThread?.typedId"
																class="truncate"
															>
																<span
																	[id]="iThread?.typedId"
																	class="text-sm font-medium text-slate-800"
																></span>
																&nbsp;
															</div>

															<div
																*ngIf="!iThread?.typedId"
																class="truncate"
																ngxTippy
																[tippyProps]="{ placement: 'auto', hideOnClick: true }"
																[ngxTippy]="iThread.name"
															>
																<span class="text-sm font-medium text-slate-800">
																	{{ iThread.name }}
																</span>
																&nbsp;
															</div>
														</div>
														<div class="flex items-center ml-2">
															<div class="text-xs inline-flex font-medium bg-indigo-400 text-white rounded-full text-center leading-5 px-2">2</div>
														</div>
													</button>
												</li>
											</ul>
										</div>
									</div>
									<div
										*ngIf="!threads[0]"
										class="flex-1 flex flex-col gap-4 items-center justify-center text-center"
									>
										<i
											class="fa-solid fa-message-bot text-slate-400"
											style="font-size: 3rem"
										></i>
										<p class="text-sm">Parece que aún no has iniciado ninguna conversación</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Messages body -->
				<div
					class="absolute top-0 bottom-0 right-0 h-full w-full lg:w-[calc(100%-18rem)] xl:w-[calc(100%-20rem)] flex flex-col overflow-hidden transition-all"
					[ngClass]="{ '!w-full': !componentStates.threadsMenu, 'translate-x-full sm:translate-x-72 lg:translate-x-0': componentStates.threadsMenu }"
				>
					<!-- Header -->
					<div>
						<div class="flex items-center justify-between bg-slate-50 border-b border-slate-200 px-4 sm:px-6 md:px-5 h-16">
							<!-- Close button -->
							<button
								*ngIf="!componentStates.threadsMenu"
								(click)="componentStates.threadsMenu = !componentStates.threadsMenu"
								class="w-8 h-8 min-w-[2rem] min-h-[2rem] mr-2 cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm"
								ngxTippy
								[tippyProps]="{ placement: 'top', hideOnClick: true }"
								[ngxTippy]="'Mostrar Conversaciones'"
							>
								<i
									class="fa-duotone fa-arrow-right-from-line opacity-75 hover:opacity-100"
									style="font-size: 1rem"
								></i>
							</button>

							<p
								class="min-w-0 max-w-full font-semibold text-slate-800 inline-block truncate mr-auto"
								ngxTippy
								[tippyProps]="{ placement: 'top', hideOnClick: true }"
								[ngxTippy]="thread?.name"
							>
								{{ thread?.name }}
							</p>

							<!-- Buttons on the right side -->
							<div class="flex">
								<button
									*ngIf="this.componentStates.maximize"
									(click)="minimize()"
									class="hidden sm:grid w-8 h-8 min-w-[2rem] min-h-[2rem] cursor-pointer place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2"
									ngxTippy
									[tippyProps]="{ placement: 'top', hideOnClick: true }"
									[ngxTippy]="'Minimizar'"
								>
									<i
										class="fa-duotone fa-arrows-minimize opacity-75 hover:opacity-100"
										style="font-size: 1rem"
									></i>
								</button>
								<button
									*ngIf="!this.componentStates.maximize"
									(click)="maximize()"
									class="hidden sm:grid w-8 h-8 min-w-[2rem] min-h-[2rem] cursor-pointer place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2"
									ngxTippy
									[tippyProps]="{ placement: 'top', hideOnClick: true }"
									[ngxTippy]="'Maximizar'"
								>
									<i
										class="fa-duotone fa-arrows-maximize opacity-75 hover:opacity-100"
										style="font-size: 1rem"
									></i>
								</button>
								<button
									(click)="close()"
									class="w-8 h-8 min-w-[2rem] min-h-[2rem] cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2"
									ngxTippy
									[tippyProps]="{ placement: 'top', hideOnClick: true }"
									[ngxTippy]="'Cerrar'"
								>
									<i
										class="fa-duotone fa-xmark opacity-75 hover:opacity-100"
										style="font-size: 1rem"
									></i>
								</button>
							</div>
						</div>
					</div>

					<!-- Body -->
					<div class="grow overflow-hidden relative flex items-end">
						<app-indeterminate-loader
							*ngIf="loadingStates.loadingThread.value || loadingStates.updatingThread.value"
							[position]="'sticky'"
							[top]="'0'"
							[left]="'0'"
						></app-indeterminate-loader>

						<div
							#messagesWrapper
							class="w-full max-h-full overflow-y-auto px-4 sm:px-6 md:px-5 py-6"
							[scrollTop]="messagesWrapper.scrollHeight"
						>
							<!-- Chat msg -->
							<ng-container *ngIf="!loadingStates.loadingThread.value">
								<ng-container *ngFor="let message of thread?.messages; let i = index">
									<!-- Date separator -->
									<div
										*ngIf="isNewDayMessage(message.created_at, thread?.messages[i - 1]?.created_at).condition"
										class="flex justify-center"
										style="overflow-anchor: none"
									>
										<div class="inline-flex items-center justify-center text-xs text-slate-600 font-medium px-2.5 py-1 bg-white border border-slate-200 rounded-full mb-5">
											{{ isNewDayMessage(message.created_at, thread?.messages[i - 1]?.created_at).date }}
										</div>
									</div>

									<!-- Assistant -->
									<div
										*ngIf="message.role == 'assistant'"
										class="flex items-start mb-4 last:mb-0"
										style="overflow-anchor: none"
									>
										<img
											class="rounded-full mr-4 min-w-[2.5rem] min-h-[2.5rem] w-[2.5rem] h-[2.5rem]"
											src="https://dev.midespacho.cloud/assets/images/isotipo.png"
										/>
										<div>
											<div class="text-sm bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-md mb-1 whitespace-pre-line">
												<p
													*ngIf="message?.typedId"
													[id]="message?.typedId"
												></p>
												<p *ngIf="!message?.typedId">
													{{ message.content[0].text.value }}
												</p>
											</div>
											<div class="flex items-center justify-between">
												<div class="text-xs text-slate-500 font-medium">
													{{ isNewDayMessage(message.created_at, thread?.messages[i - 1]?.created_at).time }}
												</div>
											</div>
										</div>
									</div>

									<!-- User -->
									<div
										*ngIf="message.role == 'user'"
										class="flex items-start mb-4 last:mb-0"
										style="overflow-anchor: none"
									>
										<div class="flex items-center mr-4 gap-4">
											<img
												class="rounded-full min-w-[2.5rem] min-h-[2.5rem] w-[2.5rem] h-[2.5rem]"
												src="https://preview.cruip.com/mosaic/images/user-40-11.jpg"
											/>
											<i
												*ngIf="message.error"
												(click)="sendMessage(message.id)"
												ngxTippy
												[ngxTippy]="'Ha ocurrido un error al enviar el mensaje.<br>Click aquí para intentar nuevamente.'"
												[tippyProps]="{ placement: 'top', allowHTML: true, hideOnClick: true }"
												class="fa-duotone fa-circle-exclamation cursor-pointer"
												style="font-size: 1.3rem"
											></i>
										</div>
										<div>
											<div class="text-sm bg-indigo-500 text-white p-3 rounded-lg rounded-tl-none border border-transparent shadow-md mb-1 whitespace-pre-line">
												{{ message.content[0].text.value }}
											</div>
											<div class="flex items-center justify-between gap-4">
												<div class="text-xs text-slate-500 font-medium">
													{{ isNewDayMessage(message.created_at, thread?.messages[i - 1]?.created_at).time }}
												</div>
												<i
													*ngIf="message.loading"
													class="fa-duotone fa-loader animate-spin"
													style="font-size: 1rem"
												></i>
												<i
													*ngIf="!message.loading && !message.error"
													class="fa-duotone fa-check"
													style="font-size: 1rem"
												></i>
											</div>
										</div>
									</div>
								</ng-container>
							</ng-container>

							<!-- Chat msg -->
							<div
								*ngIf="this.loadingStates.pendingRun.value"
								class="flex items-start mb-4 last:mb-0"
								style="overflow-anchor: none"
							>
								<img
									class="rounded-full mr-4"
									src="https://dev.midespacho.cloud/assets/images/isotipo.png"
									width="40"
									height="40"
									alt="User 01"
								/>
								<div>
									<div class="text-sm bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-md mb-1">
										<svg
											class="fill-current text-slate-400"
											viewBox="0 0 15 3"
											width="15"
											height="3"
										>
											<circle
												cx="1.5"
												cy="1.5"
												r="1.5"
											>
												<animate
													attributeName="opacity"
													dur="1s"
													values="0;1;0"
													repeatCount="indefinite"
													begin="0.1"
												></animate>
											</circle>
											<circle
												cx="7.5"
												cy="1.5"
												r="1.5"
											>
												<animate
													attributeName="opacity"
													dur="1s"
													values="0;1;0"
													repeatCount="indefinite"
													begin="0.2"
												></animate>
											</circle>
											<circle
												cx="13.5"
												cy="1.5"
												r="1.5"
											>
												<animate
													attributeName="opacity"
													dur="1s"
													values="0;1;0"
													repeatCount="indefinite"
													begin="0.3"
												></animate>
											</circle>
										</svg>
									</div>
								</div>
							</div>

							<div style="height: 1px; overflow-anchor: auto"></div>
						</div>

						<button
							*ngIf="loadingStates.loadingThread.value"
							class="btn absolute top-0 left-0 w-full h-full flex items-center justify-center"
							disabled
						>
							<svg
								class="animate-spin w-8 h-8 fill-current shrink-0"
								viewBox="0 0 16 16"
							>
								<path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
							</svg>
						</button>
					</div>

					<!-- Footer -->
					<div>
						<div class="flex items-center justify-between bg-white border-slate-200 px-4 sm:px-6 md:px-5 py-3">
							<!-- Plus button -->
							<!-- <button class="shrink-0 text-slate-400 hover:text-slate-500 mr-3">
                                <span class="sr-only">Add</span>
                                <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
                                    <path
                                        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.98 5.38 18.62.02 12 0zm6 13h-5v5h-2v-5H6v-2h5V6h2v5h5v2z">
                                    </path>
                                </svg>
                            </button> -->

							<!-- Message input -->
							<form
								[formGroup]="chatForm"
								class="grow flex items-end"
							>
								<div class="grow mr-3">
									<label
										for="message-input"
										class="sr-only"
										>Type a message</label
									>

									<app-input-field-maker
										[formGroup]="chatForm"
										[controlName]="'message'"
										[inputType]="'textarea'"
										[label]="false"
										[placeholder]="'Escribe tu consulta..'"
										[autoComplete]="false"
										[class]="'h-[2.375rem] min-h-[2.375rem] max-h-[20rem] resize-none'"
										[generalError]="false"
										[taAutoResize]="true"
										[showErrorState]="false"
									></app-input-field-maker>
								</div>
								<div>
									<button
										[disabled]="!this.chatForm.valid"
										(click)="sendMessage()"
										type="button"
										class="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap"
									>
										Enviar -&gt;
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
