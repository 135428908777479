export const environment = {
	production: false,
	apiBase: 'https://v2.dev.midespacho.cloud/',
	invalidSessionOffset: 5,
	seatsIoPublicKey: '5e28dc45-0ba2-4387-bb38-0bffea1d3212',
	firebaseConfig: {
		apiKey: 'AIzaSyBCr1gWdxZqDCT3GmGHBPzczVNgfkBXE_M',
		authDomain: 'mi-despacho-379822.firebaseapp.com',
		projectId: 'mi-despacho-379822',
		storageBucket: 'mi-despacho-379822.appspot.com',
		messagingSenderId: '122024441929',
		appId: '1:122024441929:web:c2d775a24958a8769fe798',
		measurementId: 'G-QB34STX5PJ',
		vapidKey: 'BN2BWFL6r1G58XHy7f550fuIipmgu_HyY9n4rq88OpiV5rBUKuNQ_TqUtwAVXpZaaYUg-J6vL_9E6be10i0hIAQ'
	},
    clarityProjectId: 'nqcnxtdu4u'
};
