import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalOverlayDirective } from '../../directives/modal-overlay.directive';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
	selector: 'app-image-editor-overlay',
	templateUrl: './image-editor-overlay.component.html',
	styleUrls: ['./image-editor-overlay.component.scss']
})
export class ImageEditorOverlayComponent {
	@Input() imageChangedEvent: any = '';
	@Input() aspectRatio: any = 1 / 1;

	@Output() onCancelEvent = new EventEmitter<boolean>();
	@Output() onSaveEvent = new EventEmitter<boolean>();
	@Output() onCroppedEvent = new EventEmitter<string>();

	@ViewChild('imageEditorOverlay', { read: ModalOverlayDirective })
	overlay!: ModalOverlayDirective;
	croppedImage: string | null | undefined = '';

	/* On image cropped receive event and delegate to parent */
	imageCropped(event: ImageCroppedEvent): void {
		this.croppedImage = event.base64;
		if (this.croppedImage) this.onCroppedEvent.emit(this.croppedImage);
	}

	/* Open overlay */
	openOverlay() {
		this.overlay.open();
	}

	/* Close overlay */
	closeOverlay() {
		this.overlay.close();
	}

	/* Cancel image edition */
	cancel() {
		this.imageChangedEvent = '';
		this.onCancelEvent.emit(true);
		this.closeOverlay();
	}

	/* Save image */
	save() {
		this.imageChangedEvent = '';
		this.onSaveEvent.emit(true);
		this.closeOverlay();
	}
}
