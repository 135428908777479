import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { loginData } from '../interfaces/login.interface';
import { Store, select } from '@ngrx/store';
import { sessionAuthData } from 'src/app/shared/interfaces/states-data';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DateTime } from 'luxon';
import { getSessionAuthData } from 'src/app/shared/states/session-auth/session-auth.selectors';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private basePath = 'v1/auth/';
	private sessionAuthData: sessionAuthData = {};

	constructor(
		private store: Store<any>,
		private http: HttpClient,
		private jwtHelper: JwtHelperService
	) {
		// Al recibir cambios del reducer - SessionAuth
		this.store.pipe(select(getSessionAuthData)).subscribe((data: sessionAuthData) => {
			this.sessionAuthData = data;
		});
	}

	/**
	 * Login del usuario
	 * @param data
	 * @returns
	 */
	userLogin(data: loginData): Observable<any> {
		return this.http.post(`${environment.apiBase}${this.basePath}login`, data, {
			headers: {
				'Skip-Refresh-Interceptor': 'true'
			}
		});
	}

	/**
	 * Refresar tokens del usuario
	 * @returns
	 */
	refreshToken(): Observable<any> {
		return this.http.get(`${environment.apiBase}${this.basePath}refresh`, {
			headers: {
				'X-Refresh-Token': `${this.sessionAuthData.refreshToken}`,
				'Skip-Refresh-Interceptor': 'true'
			}
		});
	}

	/**
	 * Obtener Access Token
	 */
	get getAccessToken(): string {
		return this.sessionAuthData?.accessToken || '';
	}

	/**
	 * Obtener Access Token Descodificado
	 */
	get getAccessTokenData(): any {
		if (!this.sessionAuthData?.accessToken) return null;
		return this.jwtHelper.decodeToken(this.sessionAuthData.accessToken);
	}

	/**
	 * Comprobar validez del Access Token
	 */
	get validAccessToken(): boolean {
		if (!this.sessionAuthData?.accessToken) return false;

		const token = this.jwtHelper.decodeToken(this.sessionAuthData.accessToken);

		if (DateTime.now() >= DateTime.fromSeconds(token.exp, { zone: 'UTC' }).minus({ seconds: environment.invalidSessionOffset })) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Obtener Refresh Token
	 */
	get getRefreshToken(): string {
		return this.sessionAuthData?.refreshToken || '';
	}

	/**
	 * Obtener Refresh Token Descodificado
	 */
	get getRefreshTokenData(): any {
		if (!this.sessionAuthData?.refreshToken) return null;
		return this.jwtHelper.decodeToken(this.sessionAuthData.refreshToken);
	}

	/**
	 * Comprobar validez del Refresh Token
	 */
	get validRefreshToken(): boolean {
		if (!this.sessionAuthData?.refreshToken) return false;

		const token = this.jwtHelper.decodeToken(this.sessionAuthData.refreshToken);

		if (DateTime.now() >= DateTime.fromSeconds(token.exp, { zone: 'UTC' })) {
			return false;
		} else {
			return true;
		}
	}

	ping(): Observable<any> {
		return this.http.get(`${environment.apiBase}v1/user`);
	}
}
