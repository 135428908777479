<ng-template
	appModalOverlay
	#imageEditorOverlay
	[closeOnEscape]="false"
	[closeBackdropClick]="false"
>
	<div class="bg-white rounded shadow-lg overflow-hidden w-screen h-[100dvh] max-w-[90%] max-h-[85dvh] m-auto lg:max-w-4xl flex flex-col">
		<!-- Modal header -->
		<div class="px-5 py-3 border-b bg-white border-slate-200 sticky top-0">
			<div class="flex justify-between items-center">
				<div class="font-semibold text-primary-100">Ajustar Imagen</div>
				<button
					(click)="cancel()"
					class="text-slate-400 hover:text-slate-500 close-overlay-directive"
				>
					<div class="sr-only">Cerrar</div>
					<svg class="w-4 h-4 fill-current">
						<path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"></path>
					</svg>
				</button>
			</div>
		</div>
		<!-- Modal content -->
		<div class="px-5 py-4 overflow-auto flex-1 h-full flex items-center justify-center bg-slate-300">
			<image-cropper
				[imageChangedEvent]="imageChangedEvent"
				[containWithinAspectRatio]="false"
				[maintainAspectRatio]="true"
				[resizeToHeight]="500"
				[aspectRatio]="aspectRatio"
				format="png"
				(imageCropped)="imageCropped($event)"
			></image-cropper>
		</div>
		<!-- Modal footer -->
		<div class="py-5 px-4 border-t bg-pearl border-slate-200 sticky bottom-0">
			<div class="flex flex-wrap justify-end space-x-2">
				<button
					(click)="cancel()"
					class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600 close-overlay-directive"
				>
					Cencelar
				</button>
				<button
					(click)="save()"
					class="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
				>
					Aceptar
				</button>
			</div>
		</div>
	</div>
</ng-template>
