<ng-container *ngFor="let tool of devTools">
	<div
		#debugArea
		*ngIf="debugActive"
		class="absolute z-[2000] w-full h-full top-0 pointer-events-none debug-area"
	>
		<div
			#dragHeader
			class="border border-slate-500 bg-white shadow-md overflow-visible max-w-lg w-full max-h-full pointer-events-auto"
			cdkDragBoundary=".debug-area"
			cdkDrag
			cdkAccordion
			(cdkDragStarted)="toolDragStarted($event)"
			(mousedown)="toolClicked(dragHeader)"
		>
			<cdk-accordion-item #accordionItem="cdkAccordionItem">
				<div
					class="p-2 bg-slate-200 border-slate-500 cursor-move"
					cdkDragHandle
					[ngClass]="accordionItem.expanded ? 'border-b' : ''"
				>
					<div class="flex justify-between items-center">
						<div>
							<div class="font-semibold text-[.625rem] leading-[normal] text-slate-800">{{ tool.toolName }}</div>
							<div class="font-semibold text-[.5rem] leading-[normal] text-slate-800">{{ tool.hostComponent || '&nbsp;' }}</div>
						</div>
						<button
							class="text-slate-400 hover:text-slate-500"
							(click)="accordionItem.toggle()"
						>
							<div class="sr-only">Close</div>
							<svg
								viewBox="0 0 12 12"
								class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
								[ngClass]="accordionItem.expanded ? 'rotate-180' : 'rotate-0'"
							>
								<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"></path>
							</svg>
						</button>
					</div>
				</div>
				<div
					class="p-2"
					[ngClass]="accordionItem.expanded ? 'block' : 'hidden'"
				>
					<ng-template [ngTemplateOutlet]="tool.template"></ng-template>
				</div>
			</cdk-accordion-item>
		</div>
	</div>
</ng-container>

<!-- Tool agregar tool -->
<ng-template
	appDevTool
	[registerTool]="'Herramientas de desarrollo'"
>
	<div class="text-xs">
		<div class="font-medium text-slate-800 mb-2">Active Breakpoint</div>

		<div class="flex flex-row gap-2">
			<div class="flex-1">
				<app-input-field-maker
					[inputType]="'ngselect'"
					[placeholder]="'Buscar Herramienta'"
					[searchable]="true"
					[clearable]="false"
					[items]="[]"
					[bindValue]="'value'"
					[bindLabel]="'label'"
				></app-input-field-maker>
			</div>
			<div>
				<button class="btn bg-indigo-500 hover:bg-indigo-600 text-white w-full">
					<i
						class="fa-duotone fa-plus"
						style="font-size: 1rem"
					></i>
					<span class="ml-2"> Agregar </span>
				</button>
			</div>
		</div>
	</div>
</ng-template>
