import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { experienceData } from '../../interfaces/services-data';

@Injectable({
	providedIn: 'root'
})
export class ExperiencesService {
	constructor(private http: HttpClient) {}

	/* Crear Experiencia */
	create(data: experienceData): Observable<any> {
		return this.http.post(`${environment.apiBase}v1/experience/create`, data);
	}

	/* Actualizar Experiencia */
	update(data: experienceData, experienceId: number): Observable<any> {
		return this.http.put(`${environment.apiBase}v1/experience/${experienceId}/update`, data);
	}

	/* Crear Temporada */
	createSeason(data: any): Observable<any> {
		return this.http.post(`${environment.apiBase}v1/experience/season/create`, data);
	}

	/* Actualizar Temporada */
	updateSeason(data: any, seasonId: number): Observable<any> {
		return this.http.put(`${environment.apiBase}v1/experience/season/${seasonId}/update`, data);
	}

	/* Crear Imagenes */
	imageCreate(data: any[]) {
		const formData = new FormData();

		data.forEach((media, index) => {
			const file = this.__dataURIToBlob(media.base64);
			formData.append('array_files', file, media.name);
			formData.append(`media[${index}][name]`, media.name);
			formData.append(`media[${index}][experience_id]`, media.experience_id);
			formData.append(`media[${index}][subcategory_id]`, media.subcategory_id);
		});

		return this.http.post(`${environment.apiBase}v1/experience/images/create`, formData);
	}

	/* Crear Precios */
	createPricing(data: any, experienceId: number): Observable<any> {
		return this.http.post(`${environment.apiBase}v1/experience/${experienceId}/pricing/create`, data);
	}

	/* Actualizar Precios */
	updatePricing(data: any): Observable<any> {
		return this.http.put(`${environment.apiBase}/v1/experience/pricing/update`, data);
	}

	/* Crear Función */
	createFunction(data: any): Observable<any> {
		return this.http.post(`${environment.apiBase}v1/experience/function/create`, data);
	}

	/* Generar Cortesias */
	generateCourtesy(data: any): Observable<any> {
		return this.http.post(`${environment.apiBase}v1/experience/generate-courtesy`, data);
	}

	/* Generar Usuario para cortesia */
	generateCourtesyUser(data: any): Observable<any> {
		return this.http.post(`${environment.apiBase}v1/user/register-courtesy`, data);
	}

	private __dataURIToBlob(dataURI: string) {
		const splitDataURI = dataURI.split(',');
		const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	}

	private __blobTodataURI(blob: Blob) {
		return new Promise((r) => {
			let a = new FileReader();
			a.onload = r;
			a.readAsDataURL(blob);
		}).then((e: any) => e.target.result);
	}
}
