import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef, afterNextRender } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputmaskOptions } from '@ngneat/input-mask';
import { v4 as uuidv4 } from 'uuid';
import AirDatepicker from 'air-datepicker';
import localeEs from 'air-datepicker/locale/es';
import { GlobalBaseComponent } from 'src/app/app/components/global-base/global-base.component';
import { NgLabelTemplateDirective, NgOptionTemplateDirective } from '@ng-select/ng-select';
import { createPopper } from '@popperjs/core';

@Component({
	selector: 'app-input-field-maker',
	templateUrl: './input-field-maker.component.html',
	styleUrls: ['./input-field-maker.component.scss']
})
export class InputFieldMakerComponent extends GlobalBaseComponent implements AfterViewInit {
	// Events
	@Output() onChangeEvent = new EventEmitter<any>();
	@Output() onSelectChangeEvent = new EventEmitter<any>();
	@Output() onInputEvent = new EventEmitter<any>();
	@Output() onSearchEvent = new EventEmitter<any>();
	@Output() onBlurEvent = new EventEmitter<any>();
	@Output() onFocusEvent = new EventEmitter<any>();
	@Output() onOpenEvent = new EventEmitter<any>();

	// Objects
	@Output() onDatePickerInstanceReady = new EventEmitter<AirDatepicker<HTMLElement>>();
	@Output() onDatePickerInstanceShow = new EventEmitter<AirDatepicker<HTMLElement>>();
	@Output() onDatePickerInstanceHide = new EventEmitter<AirDatepicker<HTMLElement>>();

	// General
	@Input() inputType: string = 'text';
	@Input() controlName!: string;
	@Input() formGroup!: FormGroup<any>;
	@Input() label: string | boolean = '';
	@Input() placeholder: string = '';
	@Input() disabled: boolean = false;
	@Input() readOnly: boolean = false;
	@Input() showErrorState: boolean = true;
	@Input() generalError: string | boolean = '';
	@Input() class: string = '';
	@Input() labelClick: boolean = true;
	@Input() loadingState: boolean = false;

	// TextArea
	@Input() taAutoResize: boolean = false;

	// Number
	@Input() min: number | string = '';
	@Input() max: number | string = '';

	// Html Input
	@Input() autoComplete: boolean = false;
	@Input() required: boolean = false;
	@Input() spellCheck: boolean = true;
	@Input() icon: string = '';
	@Input() iconPos: string = 'right';

	// Switch
	@Input() leftLabel: string = '';
	@Input() rightLabel: string = '';

	// Radio
	@Input() value: number | string | boolean = '';

	// NgSelect
	@Input() items: any[] | null = [];
	@Input() multiple: boolean = false;
	@Input() bindLabel: string = '';
	@Input() bindValue: string = '';
	@Input() searchable: boolean = true;
	@Input() clearable: boolean = true;
	@Input() searchFn: any;
	@ContentChild(NgLabelTemplateDirective, { read: TemplateRef }) selectLabelTemplate!: TemplateRef<any>;
	@ContentChild(NgOptionTemplateDirective, { read: TemplateRef }) selectOptionTemplate!: TemplateRef<any>;

	// Mask
	@Input() inputMask!: InputmaskOptions<any> | null;

	// Date
	@Input() timepicker: boolean = false;
	@Input() startDate!: Date;
	@Input() positionContainer: string = '';

	DatepickerInstance!: AirDatepicker<HTMLElement>;

	inputSignature!: string;

	constructor(private elementRef: ElementRef) {
		super();

		// Generar un uuid para el input
		const inputID = uuidv4();

		// Si no hay controlName utilizar solo el uuid
		this.inputSignature = this.controlName ? `input-${this.controlName}-${inputID}` : `input-${inputID}`;

		// Si no hay controlName utilizar solo el uuid
		this.controlName = this.controlName ? this.controlName : inputID;

		// Si no se recibe un formgroup crear uno local
		if (!this.formGroup) {
			this.formGroup = this.formBuilder.group({
				[this.controlName]: ['', []]
			});
		}

		afterNextRender(() => {
			if (this.disabled) {
				this.formGroup.get(this.controlName)?.disable();
			}

			if (this.inputType == 'date') {
				// Boton para establecer la fecha actual
				const todayButton = {
					content: 'Hoy',
					onClick: (dp: AirDatepicker) => {
						let date = new Date();
						dp.selectDate(date);
						dp.setViewDate(date);
					}
				};

				this.DatepickerInstance = new AirDatepicker(`#${this.inputSignature}`, {
					locale: localeEs,
					timepicker: this.timepicker,
					timeFormat: 'hh:mm AA',
					container: this.positionContainer ? this.positionContainer : '',
					buttons: [todayButton, 'clear'],
					startDate: this.startDate ? this.startDate : new Date(),
					position({ $datepicker, $target, $pointer, done }) {
						const remToPx = (rem: number): number => {
							let fontSizeInPixels = parseFloat(window.getComputedStyle(document.body).fontSize);
							return rem * fontSizeInPixels;
						};

						const popper = createPopper($target, $datepicker, {
							placement: 'top',
							modifiers: [
								{
									name: 'flip',
									options: {
										padding: {
											top: remToPx(4)
										}
									}
								},
								{
									name: 'offset',
									options: {
										offset: [0, remToPx(1.25)]
									}
								},
								{
									name: 'arrow',
									options: {
										element: $pointer
									}
								}
							]
						});

						/*
                            Return function which will be called when `hide()` method is triggered,
                            it must necessarily call the `done()` function
                            to complete hiding process 
                        */

						return function completeHide() {
							popper.destroy();
							done();
						};
					},
					onSelect: (e: any) => {
						this.formGroup.controls[this.controlName].patchValue(e.formattedDate || '');
					},
					onShow: (e: any) => {
						this.onDatePickerInstanceShow.emit(this.DatepickerInstance);
					},
					onHide: (e: any) => {
						this.onDatePickerInstanceHide.emit(this.DatepickerInstance);
					}
				});

				// Si la fecha inicial se envia por parametro se establece como fecha inicial
				if (this.startDate) {
					this.DatepickerInstance.selectDate(this.startDate ? this.startDate : new Date());
				}

				//this.DatepickerInstance.selectDate

				this.onDatePickerInstanceReady.emit(this.DatepickerInstance);
			}

			this.resizeTextarea();

			// Detectar cambios para evitar errores
			// this.cdr.detectChanges();
		});
	}

	override ngAfterViewInitOverride(): void {}

	resizeTextarea() {
		if (this.taAutoResize) {
			// Convertir Rem a Px
			const remToPx = (rem: number): number => {
				let fontSizeInPixels = parseFloat(taStyle.fontSize);
				return rem * fontSizeInPixels;
			};

			const textarea = this.elementRef.nativeElement.querySelector('textarea');
			const taStyle = window.getComputedStyle ? window.getComputedStyle(textarea) : textarea.currentStyle;

			textarea.style.height = '1px';
			textarea.style.height = textarea.scrollHeight + remToPx(0.125) + 'px';
			textarea.scrollTop = 9e9;

			// Fallback
			setTimeout(() => {
				textarea.scrollTop = 9e9;
			}, 0);
		}
	}

	onInput($event: any) {
		if (this.clientRenderReady.value) {
			this.resizeTextarea();
			this.onInputEvent.emit($event.target.value);
		}
	}

	onChange($event: any) {
		if (this.clientRenderReady.value) {
			this.resizeTextarea();

			// Verificar si el tipo de entrada es un interruptor
			if (this.inputType == 'switch') {
				this.onChangeEvent.emit($event);
				return;
			}

			// Actualizar el valor del formulario para los radios
			if (this.inputType === 'radio') {
				this.formGroup.controls[this.controlName].setValue(this.value);
				this.onChangeEvent.emit($event);
				return;
			}

			// Emitir evento de cambio para otros tipos de entrada
			if ($event) {
				this.onChangeEvent.emit($event.target ? $event.target.value : $event);
			}
		}
	}

	onSearch($event: any) {
		if (this.clientRenderReady.value) {
			this.onSearchEvent.emit($event);
		}
	}

	onBlur($event: any) {
		if (this.clientRenderReady.value) {
			this.onBlurEvent.emit($event);
		}
	}

	onFocus($event: any) {
		if (this.clientRenderReady.value) {
			this.onFocusEvent.emit($event);
		}
	}

	onOpen($event: any) {
		if (this.clientRenderReady.value) {
			this.onOpenEvent.emit($event);
		}
	}

	onNgSelectChange($event: any) {
		if (this.clientRenderReady.value) {
			this.onSelectChangeEvent.emit($event);
		}
	}
}
