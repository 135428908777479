<div
	class="max-w-full w-full fixed"
	[style.zIndex]="zIndex"
	[style.position]="position"
	[style.left]="left"
	[style.top]="top"
>
	<div class="h-0 w-full overflow-visible">
		<div class="h-1.5 w-full bg-pink-100 overflow-hidden">
			<div class="animate-progress w-full h-full bg-indigo-500 origin-left-right"></div>
		</div>
	</div>
</div>
