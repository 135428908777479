import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { from, Observable } from 'rxjs';
import { ALERT_DEFAULTS, ALERT_TOAST_DEFAULTS } from '../constants/alert-defaults.constants';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AlertService {
	showModal(options: SweetAlertOptions): Observable<SweetAlertResult> {
		return from(
			Swal.fire({
				...ALERT_DEFAULTS,
				...(options as any)
			})
		);
	}

	showToast(options: SweetAlertOptions): Observable<SweetAlertResult> {
		return from(
			Swal.fire({
				...ALERT_TOAST_DEFAULTS,
				...(options as any)
			})
		);
	}
}
