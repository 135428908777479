import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ModalOverlayDirective } from '../../directives/modal-overlay.directive';

export interface ModalActionData {
	title?: string;
	message?: string;
	closeOnEscape?: boolean;
	closeBackdropClick?: boolean;
	icon?: 'warning' | 'info' | 'error' | 'success';
}

@Component({
	selector: 'app-modal-action',
	templateUrl: './modal-action.component.html',
	styleUrls: ['./modal-action.component.scss']
})
export class ModalActionComponent implements OnInit, OnDestroy {
	@Input() config: ModalActionData = {
		title: 'Titulo',
		message: 'Mensaje',
		closeOnEscape: false,
		closeBackdropClick: false
	};
	@Output() onClose = new EventEmitter<boolean>();
	@ViewChild('modalActionOverlay', { static: true }) modalReference!: ModalOverlayDirective;

	constructor() {}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	open() {
		setTimeout(() => {
			this.modalReference.open();
		}, 0);
	}

	confirm() {
		this.onClose.emit(true);
		this.modalReference.close();
	}

	cancel() {
		this.onClose.emit(false);
		this.modalReference.close();
	}
}
